import '../css/TopBarStyle.css'
import PageTitle from './PageNameComponent'
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from 'react';

function getCurrentPageTitle(currentPath) {
    switch (currentPath) {
        case "/projects":
            document.title = "George Sepetadelis | My projects"
            return "My projects"
        case "/about":
            document.title = "George Sepetadelis | About me"
            return "About me"
        case "/contact":
            document.title = "George Sepetadelis | Contact"
            return "Contact me"
        default:
            document.title = "George Sepetadelis | Home"
            return "Home"
    }
}

function TopNavBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className='root-nav'>
            <div className="container">
            <div className='logo-div'>
                <PageTitle currentPageName={ getCurrentPageTitle(location.pathname) } />
            </div>

            <div className={`navigation-buttons ${menuOpen ? 'open' : ''}`}>
                <button id='homeNavButton' className={`${location.pathname === '/' ? 'nav-button-active' : 'nav-button'}`} onClick={() => navigate("/", { replace: true })}>🏡 Home</button>
                <button id='myProjectsNavButton' className={`${location.pathname === '/projects' ? 'nav-button-active' : 'nav-button'}`} onClick={() => navigate("/projects", { replace: true })}>🎮 My Projects</button>
                <button id='aboutMeNavButton' className={`${location.pathname === '/about' ? 'nav-button-active' : 'nav-button'}`} onClick={() => navigate("/about", { replace: true })}>🎯 About me</button>
                <button id='contactNavButton' className={`${location.pathname === '/contact' ? 'nav-button-active' : 'nav-button'}`} onClick={() => navigate("/contact", { replace: true })}>☎️ Contact</button>
            </div>

            {/* Hamburger Icon */}
            <div className="hamburger" onClick={toggleMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </div>
            </div>
        </div>
        
    );
}

export default TopNavBar;
