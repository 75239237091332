import TopNavBar from "../TopBarComponent";
import React  from 'react';

function PageNotFound() {
    return(
        <div>
            <TopNavBar />
            <center>
                <h1 style={{color: 'white'}}>Page not found</h1>
            </center>
        </div>
    )
}

export default PageNotFound;