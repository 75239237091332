import React, { useEffect, useState } from 'react';
import TopNavBar from "../TopBarComponent";
import '../../css/ProjectsStyle.css';
import ProjectCard from '../CustomComponents/ProjectCard';
import firebase from 'firebase/compat/app';
import database from 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyBWlaUduGJwcttNDUnjdcQOPztmvAgEWf4",
    authDomain: "mypersonalwebsite-60614.firebaseapp.com",
    databaseURL: "https://mypersonalwebsite-60614-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mypersonalwebsite-60614",
    storageBucket: "mypersonalwebsite-60614.appspot.com",
    messagingSenderId: "160672326450",
    appId: "1:160672326450:web:2d924197cacfe1d11fad2b"
  };

firebase.initializeApp(firebaseConfig);

function Projects() {
  const [fadeIn, setFadeIn] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setFadeIn(true);

    // Fetch data from Firebase Realtime Database
    const projectsRef = firebase.database().ref('projects');
    projectsRef.once('value', (snapshot) => {
      const projectData = snapshot.val();
      const projectList = Object.keys(projectData).map(key => projectData[key]);
      setProjects(projectList);
    });
  }, []);

  return (
    <div className="App">
      <div className='nav-container'>
        <TopNavBar className="navbar" />  {/* Render TopNavBar component */}
      </div>

      <div className={`title ${fadeIn ? 'fade-in' : ''}`}>
        <h1 className={`name-title-projects ${fadeIn ? 'fade-in' : ''}`}>Some of my projects</h1>
        <p className={`desc-title-projects ${fadeIn ? 'fade-in' : ''}`}>Some of the projects I've worked on</p>
      </div>

      <div className='projects-container'>
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            imagePath={project.imgUrl}
            title={project.name}
            description={project.desc}
          />
        ))}
      </div>

    </div>
  );
}

export default Projects;