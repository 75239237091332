import React, { useEffect, useState } from 'react';
import TopNavBar from "../TopBarComponent";
import '../../css/AboutMeStyle.css';

function AboutMe() {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    function findMyAge(birthDateStr) {
        const [day, month, year] = birthDateStr.split('/').map(Number);
        const birthDate = new Date(year, month - 1, day);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();    
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return (
        <div className='App'>
            <TopNavBar />

            <div className={`title ${fadeIn ? 'fade-in' : ''}`}>
                <h1 className={`name-title-about ${fadeIn ? 'fade-in' : ''}`}>More about me</h1>
                <p className={`desc-title-about ${fadeIn ? 'fade-in' : ''}`}>
                    Hello! I'm George Sepetadelis,<br /> I'm {findMyAge("3/2/2006")} years old and I'm passionate about creating exceptional digital experiences. <br />
                    I specialize in developing mobile native and cross-platform apps, crafting immersive games in Unreal Engine, and 
                    building impressive websites using React and Angular. With my expertise and attention to detail, 
                    I strive to deliver cutting-edge solutions that captivate users and bring ideas to life. Let's 
                    collaborate and turn your vision into reality!
                    <br></br><br></br>
                    <strong>Good luck finding someone more hard-working than me.</strong>
                </p>
            </div>

            <div className={`roadmap ${fadeIn ? 'fade-in' : ''}`}>
                <div className="roadmap-event left">
                    <div className="event-content">
                        <h3>2024 - Full-Stack Development & UE5</h3>
                        <p>In 2024, I dove deep into Unreal Engine 5 (UE5) while working on my latest game, <a className='link' href='https://lyra.rf.gd'>Lyra</a>. This marked a major milestone in my game development journey. Additionally, I began working as a full-stack web developer at <a className='link' href='https://cces.gr'>CCES</a>, contributing to numerous projects at <a className='link' href='https://edumetrisis.com'>Edumetrisis</a> using .NET and Angular. A highlight was rewriting my Android app, <a className='link' href='https://socialmeme.app/download'>Social Meme</a>, in Flutter, which is now published on both the Play Store and App Store.</p>
                    </div>
                </div>
                <div className="roadmap-event right">
                    <div className="event-content">
                        <h3>2023 - Mobile Development & Game Releases</h3>
                        <p>2023 was a big year for mobile development and Unreal Engine. I released my first game, <a className='link' href='https://racefantasy.rf.gd/'>Race Fantasy</a>, built with UE5. Alongside this, I expanded the <strong>Lyra Starter Game</strong> into my own personal project, and further developed my native Android app, <a className='link' href='https://socialmeme.app/download'>Social Meme</a>.</p>
                    </div>
                </div>
                <div className="roadmap-event left">
                    <div className="event-content">
                        <h3>2022 - Breaking into Game Development</h3>
                        <p>2022 marked my full embrace of game development, starting with Unreal Engine. I launched my first game,  <a className='link' href='https://racefantasy.rf.gd/'>Race Fantasy</a>, and maintained the <strong>iGrow Series</strong> apps for <a className='link' href='https://www.psta.net.au/'>PSTA</a>, building my expertise in both game and app development.</p>
                    </div>
                </div>
                <div className="roadmap-event right">
                    <div className="event-content">
                        <h3>2021 - Cross-Platform Mobile Development</h3>
                        <p>In 2021, I shifted focus to cross-platform development with <strong>Flutter</strong> and <strong>Firebase</strong>. I developed and launched the second app in the iGrow Series, <strong>iGrow MHFA</strong>, marking a new chapter in my mobile app journey.</p>
                    </div>
                </div>
                <div className="roadmap-event left">
                    <div className="event-content">
                        <h3>2020 - Native Mobile Development</h3>
                        <p>2020 was the year I worked on <strong>iGrow Goals</strong> for <a className='link' href='https://www.psta.net.au/'>PSTA</a>, publishing native Android and iOS versions of the app. The iOS version was my first major project using <strong>SwiftUI</strong>, further diversifying my mobile development skillset.</p>
                    </div>
                </div>
                <div className="roadmap-event right">
                    <div className="event-content">
                        <h3>2019 - The Beginning of My Journey</h3>
                        <p>In 2020, I decided to bring my ideas to life and started developing apps with Android Studio on my 2GB RAM laptop/toaster. Despite the limitations, my passion for mobile app development and programming grew, and the rest is history...</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
