import React from 'react';
import './ProjectCard.css';

const ProjectCard = ({ imagePath, title, description }) => {
  return (
    <div className="project-card">
      <img src={imagePath} alt={title} className="project-card-image" />
      <h3 className="project-card-title">{title}</h3>
      <p className="project-card-description">{description}</p>
    </div>
  );
};

export default ProjectCard;
