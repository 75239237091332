import React, { useEffect, useState } from 'react';
import TopNavBar from "../TopBarComponent";
import github_icon from '../../assets/github_icon.png';
import instagram_icon from '../../assets/instagram_icon.png';
import my_picture from '../../assets/my_picture.jpeg';
import cv_icon from '../../assets/CV_img.png';
import '../../css/ContactStyle.css';

function Contact() {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <div>
            <TopNavBar />

            <div className={`contact-container ${fadeIn ? 'fade-in' : ''}`}>
                <div className="contact-info">
                    <h1 className="name-title">Looking to collaborate?</h1>
                    
                    {/* Horizontal div with image and text */}
                    <div className="profile-summary">
                        <div className="profile-image">
                            <img src={my_picture} alt="George Sepetadelis" />
                        </div>
                        <div className="profile-details">
                            <h2 className="name">GEORGE SEPETADELIS</h2>
                            <h3 className='name-sub'>Software Engineer</h3>
                        </div>
                    </div>

                    <p className="desc-title-contact">Do you want to work together? <br /> I will be glad to hear your ideas.</p>
                    <a href="mailto:giorgossepetadelis11@gmail.com" className="contact-button">SEND ME AN EMAIL</a>
                    <p className="or-text">OR</p>

                    <div className="social-links">
                        <a href="https://github.com/georgesepetadelis/">
                            <img src={github_icon} alt="GitHub" />
                        </a>
                        <a href="https://www.instagram.com/sepetadelhsss/">
                            <img src={instagram_icon} alt="Instagram" />
                        </a>
                        <a href="https://twitter.com/gsepetadelis">
                            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/social-twitter.svg" alt="Twitter" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
