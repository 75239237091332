import React, { useEffect, useState } from 'react';
import TopNavBar from '../TopBarComponent';
import lyra_cover from '../../assets/projects/lyra_cover.png';
import socialmeme from '../../assets/projects/social_meme.png';
import race_fantasy from '../../assets/projects/race_fantasy.png';
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [fadeIn, setFadeIn] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [lyra_cover, socialmeme, race_fantasy];

  useEffect(() => {
    setFadeIn(true);
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeIn(true); 
      }, 500); 
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  document.title = "George Sepetadelis | Home";

  return (
    <div className="App">
      <TopNavBar />
      <div className={`content-container ${fadeIn ? 'fade-in' : ''}`}>
        <div className='text-container'>
          <p className={`hello-title ${fadeIn ? 'fade-in' : ''}`}>Hello, I'm</p>
          <h1 className={`name-title-home ${fadeIn ? 'fade-in' : ''}`}>George Sepetadelis</h1>
          <p className={`desc-title ${fadeIn ? 'fade-in' : ''}`}>Mobile/Game Developer</p>
          <button className={`action-button-home`} onClick={() => { navigate("/contact", { replace: true }) }}>Contact me</button>
        </div>

        <div className='image-root'>
          <div className="image-container">
            <img src={images[currentImageIndex]} alt="Project Image" className={`project-image ${fadeIn ? 'fade-in' : ''}`} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
